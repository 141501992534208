import {
  Info,
  Award,
  Briefcase,
  Archive,
  UserCheck,
  Columns,
  Users,
  Clipboard,
  BarChart2,
  User,
} from "react-feather";

const adminSection = [
  {
    href: "/admin/dashboard",
    icon: Info,
    title: "Dashboard",
  },
  {
    href: "/admin/students",
    icon: Award,
    title: "Students",
  },
  {
    href: "/admin/teachers",
    icon: User,
    title: "Teachers",
  },
  {
    href: "/admin/news-and-announcements",
    icon: Users,
    title: "News & Announcements",
  },
  {
    href: "/admin/health-statements",
    icon: Archive,
    title: "Health Statements",
  },
];

const broadcastingSection = [
  {
    href: "/admin/srd-device",
    icon: Archive,
    title: "SRD Device",
  },
  {
    href: "/admin/streaming",
    icon: Archive,
    title: "Streaming",
  },
  {
    href: "/admin/ussd",
    icon: Archive,
    title: "USSD",
  },
];

const settingsSection = [
  {
    href: "/admin/profile",
    icon: Archive,
    title: "Profile",
  },
  {
    href: "/admin/languages",
    icon: Archive,
    title: "Languages",
  },
  {
    href: "/admin/accessiblity",
    icon: Archive,
    title: "Accessibility",
  },
];

const navItems = [
  {
    title: "Navigation",
    pages: adminSection,
  },
  {
    title: "Broaddcasting",
    pages: broadcastingSection,
  },
  {
    title: "Settings",
    pages: settingsSection,
  },
];

export default navItems;
