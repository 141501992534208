import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import counterReducer from "./slices/counter";
import userReducer from "./slices/user";
import authSlice from "./slices/authSlice";
//RTK QUERY
export const store = configureStore({
  reducer: {
    user: userReducer,
    authSlice: authSlice,
  },
});
