import {
  Info,
  Award,
  Briefcase,
  Archive,
  BookOpen,
  UserCheck,
  Mail,
  Columns,
  Users,
  Clipboard,
  BarChart2,
  Globe,
  User,
  Disc,
} from "react-feather";

const adminSection = [
  {
    href: "/parent",
    icon: Info,
    title: "Dashboard",
  },
  {
    href: "/parent/finance",
    icon: BarChart2,
    title: "Finance",
  },
];

const settingsSection = [
  {
    href: "/parent/profile",
    icon: User,
    title: "My Information",
  },
  {
    href: "/parent/language",
    icon: Globe,
    title: "Languages",
  },
  {
    href: "/parent/accessibility",
    icon: Disc,
    title: "Accessibility",
  },
];

const navItems = [
  {
    title: "Navigation",
    pages: adminSection,
  },
  {
    title: "Settings",
    pages: settingsSection,
  },
];

export default navItems;
