import {
  Info,
  Award,
  Briefcase,
  Archive,
  BookOpen,
  UserCheck,
  Mail,
  Disc,
  Columns,
  Users,
  Clipboard,
  BarChart2,
  Globe,
  User,
  Box,
  Wifi,
  Hash,
} from "react-feather";

const adminSection = [
  {
    href: "/parent",
    icon: Info,
    title: "Dashboard",
  },
  {
    href: "/parent/results",
    icon: BarChart2,
    title: "Academic Results",
  },
  {
    href: "/parent/rewards",
    icon: Award,
    title: "Rewards & Punishments",
  },
  {
    href: "/parent/curriculum",
    icon: BookOpen,
    title: "Curriculum & Notes",
  },
  {
    href: "/parent/attendance",
    icon: UserCheck,
    title: "Attendance",
  },
  {
    href: "/parent/news",
    icon: Mail,
    title: "News & Announcements",
  },
  {
    href: "/parent/my-school",
    icon: Briefcase,
    title: "My School",
  },
];

const broadcastingSection = [
  {
    href: "/parent/broadcast/srd-device",
    title: "SRD Device  ",
    icon: Box,
  },
  {
    href: "/parent/broadcast/streaming",
    title: "Streaming",
    icon: Wifi,
  },
  {
    href: "/parent/broadcast/ussd",
    title: "USSD",
    icon: Hash,
  },
];

const settingsSection = [
  {
    href: "/parent/profile",
    icon: User,
    title: "My Information",
  },
  {
    href: "/parent/language",
    icon: Globe,
    title: "Languages",
  },
  {
    href: "/parent/accessibility",
    icon: Disc,
    title: "Accessibility",
  },
];

const navItems = [
  {
    title: "Navigation",
    pages: adminSection,
  },
  {
    title: "Broadcasting",
    pages: broadcastingSection,
  },
  {
    title: "Settings",
    pages: settingsSection,
  },
];

export default navItems;
