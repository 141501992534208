import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import useAppSelector from "../hooks/useAppSelector";
import { useSelector } from "react-redux";

// import dashboardItems from "../components/sidebar/dashboardItems";
import parentItems from "../components/sidebar/parentItems";
import parentPassiveItems from "../components/sidebar/parentPassiveItems";
const Parent = ({ children }) => {
  var itemsSource = parentItems;
  const activeStudentId = useAppSelector(
    (state) => state.parentStudent.student_id
  );
  console.log("Parent Layout: ", activeStudentId);
  if (activeStudentId === null) {
    itemsSource = parentPassiveItems;
  }
  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar items={itemsSource} />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
};
export default Parent;
