import React from "react";

//Layouts
import DashboardLayout from "./layouts/Dashboard";
import LandingLayout from "./layouts/Landing";
import ParentLayout from "./layouts/Parent";
import DocLayout from "./layouts/Doc";
import AuthLayout from "./layouts/Auth";
import StudentLayout from "./layouts/Student";

//Guards
import AuthGuard from "./components/guards/AuthGuard";
import AdminGuard from "./components/guards/AdminGuard";
import GuestGuard from "./components/guards/GuestGuard";

//AMDIN PAGES
import AdminDash from "./pages/admin/dash/AdminDash";

//PARENT PAGES

const routes = [
  {
    path: "/",
    element: <LandingLayout></LandingLayout>,
  },
  {
    path: "/admin",
    element: <DashboardLayout />,
    children: [
      {
        path: "dashboard",
        element: <AdminDash />,
      },
    ],
  },
  {
    path: "/parent",
    element: <ParentLayout />,
    children: [],
  },
  {
    path: "/student",
    element: <StudentLayout />,
    children: [],
  },
];

export default routes;
