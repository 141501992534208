import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";

const AdminDash = () => {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Dashboard</h1>
      </Container>
    </React.Fragment>
  );
};

export default AdminDash;
